import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'apps/pdp-rework-hub-buy-selector/src/store';
import { useCallback } from 'react';
import {
  IMaster,
  isPriceRange,
  ISubscription,
  SelectionOptions,
  SubscriptionPromotion,
} from '@pdp-rework-hub/utils-types';
import { SELECTOR_OPTION_PRODUCT, SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE } from '@pdp-rework-hub/utils-constants';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import { setSelectedOption } from '@pdp-rework-hub/slices';

type TUsePriceSelectorData = {
  selectedOption: SelectionOptions;
  upgradeRequired: boolean;
  freeTrialPromotion?: SubscriptionPromotion;
  displayedSubscriptions?: ISubscription[];
  displayPrice: {
    product: string;
    subscription: string | undefined;
  };
  handlePriceSelectorClick: (option: SelectionOptions) => void;
  isActiveSubscriber: boolean;
};

export const usePriceSelectorData = (): TUsePriceSelectorData | null => {
  const { products, selectedOption, loaded, subscriptions, activeSubscription, upgradeRequired, isActiveSubscriber } =
    useSelector((state: RootState) => ({
      products: state.products.data.product,
      subscriptions: state.products.availableSortedSubscriptions,
      selectedOption: state.products.selectedOption,
      loaded: state.products.selectedOption,
      activeSubscription: state.user.activeSubscription,
      upgradeRequired: state.user.upgradeRequired,
      isActiveSubscriber: state.user.isActiveSubscriber,
    }));

  const dispatch = useAppDispatch();

  const handlePriceSelectorClick = useCallback(
    (option: SelectionOptions) => {
      const baseTrackingEvent = {
        location: 'buy selector',
        locationPosition: 'overlay',
      };
      const clickEvent = {
        ...baseTrackingEvent,
        category: 'interaction',
        locationDetail: option === SELECTOR_OPTION_PRODUCT ? 'subscribe' : 'buy',
        action: option === SELECTOR_OPTION_PRODUCT ? 'buy' : 'subscribe',
        slotNumber: 1,
      };
      sendAdobeEvent(clickEvent);
      dispatch(setSelectedOption(option));
    },
    [dispatch, setSelectedOption]
  );

  const displayedSubscriptions = subscriptions?.filter((subscription) => subscription.id !== activeSubscription?.id);

  if (!products || (loaded && !products.priceObject && !products.masters.length) || !displayedSubscriptions?.length) {
    return null;
  }

  const lowestEditionPrice = getLowestEditionPrice(products.masters);

  // Subscription price management
  const freeTrialPromotion = getFreeTrialPromotion(displayedSubscriptions);
  const lowestSubscriptionPrice = getLowestSubscriptionPrice(displayedSubscriptions);

  return {
    displayedSubscriptions,
    selectedOption,
    upgradeRequired,
    displayPrice: {
      product: lowestEditionPrice,
      subscription: lowestSubscriptionPrice,
    },
    handlePriceSelectorClick,
    freeTrialPromotion,
    isActiveSubscriber,
  };
};

export const getFreeTrialPromotion = (subscriptions?: ISubscription[]) => {
  if (!subscriptions || !subscriptions.length) {
    return;
  }

  return subscriptions.reduce<SubscriptionPromotion | undefined>((promotion, subscription) => {
    if (!subscription.promotions.length) {
      return promotion;
    }

    const freeTrialPromotion = subscription.promotions.find(
      (promotion) => !!promotion.type && promotion.type === SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE
    );

    return freeTrialPromotion;
  }, undefined);
};

export const getLowestSubscriptionPrice = (subscriptions?: ISubscription[]) => {
  if (!subscriptions || (!subscriptions.length && !subscriptions[0])) {
    return;
  }

  const lowestSubscription = subscriptions[0];

  return lowestSubscription.discountedPriceFormatted || lowestSubscription.basePriceFormatted;
};

export const getLowestEditionPrice = (masters: IMaster[]) => {
  const lowestPricedMaster = masters.reduce((prev, curr) => {
    const prevPrice = isPriceRange(prev.price) ? prev.price.min.sales.value : prev.price.sales.value;
    const currPrice = isPriceRange(curr.price) ? curr.price.min.sales.value : curr.price.sales.value;

    return prevPrice < currPrice ? prev : curr;
  });

  return isPriceRange(lowestPricedMaster.price)
    ? lowestPricedMaster.price.min.sales.formatted
    : lowestPricedMaster.price.sales.formatted;
};
