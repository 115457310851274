import {
  IAdobeProductClickEvent,
  IMaster,
  IProductModel,
  ISubscription,
  IVariation,
  IAdobeEvent,
} from '@pdp-rework-hub/utils-types';
import { sendAdobeEvent, prepareProductEvent, filterActivePromotionsFromSubscriptions } from '@pdp-rework-hub/utils';

const sendProductEditionEvent = (
  selectedMaster: IMaster,
  selectedVariant: IVariation,
  selectedPlatform: string,
  productData: IProductModel,
  category: 'interaction' | 'selected' = 'interaction'
) => {
  const actionType = category === 'selected' ? 'selected' : 'select';
  // Tracking event to send the selected edition from variant product
  const baseTrackingEventEdition = {
    location: 'buy selector',
    locationDetail: 'buy',
    action: `edition : ${selectedMaster.name} : ${actionType}`,
    category,
    locationPosition: 'overlay',
    slotNumber: 2,
  };
  const productTrackingDetailsEdition = prepareProductEvent(productData, selectedMaster);
  const trackingEventEdition = {
    ...baseTrackingEventEdition,
    ...productTrackingDetailsEdition,
  };
  sendAdobeEvent(trackingEventEdition);

  // Tracking event to send the selected platform from variant product
  const baseTrackingEventPlatform = {
    location: 'buy selector',
    locationDetail: 'buy',
    action: `platform : ${selectedPlatform} : ${actionType}`,
    category,
    locationPosition: 'overlay',
    slotNumber: 3,
  };
  const productTrackingDetailsPlatform = prepareProductEvent(productData, selectedMaster, selectedVariant);
  const trackingEventPlatform = {
    ...baseTrackingEventPlatform,
    ...productTrackingDetailsPlatform,
  };
  sendAdobeEvent(trackingEventPlatform);
};

const sendSubscriptionEvent = (
  selectedSubscription: ISubscription,
  category: 'interaction' | 'selected' = 'interaction'
) => {
  const actionType = category === 'selected' ? 'selected' : 'select';
  const filteredPromotions = filterActivePromotionsFromSubscriptions([selectedSubscription]);
  const trackingEvent: IAdobeProductClickEvent = {
    location: 'buy selector',
    locationDetail: 'subscribe',
    action: `offer : ${selectedSubscription.name} : ${actionType}`,
    category,
    locationPosition: 'overlay',
    slotNumber: 2,
    productId: selectedSubscription.id,
    productName: selectedSubscription.name,
    productDiscount: filteredPromotions.length ? 'yes' : 'no',
    productSalesType: 'subscription',
  };

  if (selectedSubscription.percentageOff) {
    trackingEvent.productDiscountRate = selectedSubscription.percentageOff / 100;
  }

  if (filteredPromotions.length) {
    trackingEvent.productDiscountName = filteredPromotions[0].type;
  }
  sendAdobeEvent(trackingEvent);
};

const sendSubscriptionPlanEvent = () => {
  const trackingEvent: IAdobeEvent = {
    location: 'buy selector',
    locationDetail: 'subscribe',
    action: `billing frequency : Ubisoft+ Monthly : selected`,
    category: 'selected',
    locationPosition: 'overlay',
  };

  sendAdobeEvent(trackingEvent);
};

export { sendProductEditionEvent, sendSubscriptionEvent, sendSubscriptionPlanEvent };
