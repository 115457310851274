import React from "react";

interface IGooglePlayIconProps {
  width?: number;
  height?: number;
}

const GooglePlayIcon = ({width = 42, height = 49}: IGooglePlayIconProps) => (
  <svg width={width} height={height} viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M5.13232 0.689941C6.8373 1.13119 8.21241 2.24244 9.69264 3.12744C16.4969 7.19619 23.2818 11.2999 30.0739 15.3912C30.1899 15.4612 30.3011 15.5387 30.4352 15.6262C28.1478 17.9524 25.8785 20.2599 23.6624 22.5149C16.782 15.4449 9.82677 8.29494 2.82437 1.09744C3.23521 0.949941 3.59409 0.819941 3.95418 0.689941C4.34689 0.689941 4.7396 0.689941 5.13232 0.689941Z" 
      fill="white"
    />
    <path 
      d="M3.95417 48.6899C3.60496 48.5724 3.25574 48.4549 2.84974 48.3174C9.81589 41.0774 16.7252 33.8974 23.608 26.7437C25.893 29.0924 28.1514 31.4124 30.4328 33.7574C30.2902 33.8524 30.1573 33.9474 30.0171 34.0312C22.3695 38.6437 14.7254 43.2612 7.0693 47.8587C6.49292 48.2049 5.8392 48.4162 5.22173 48.6899H3.95296H3.95417Z" 
      fill="white"
    />
    <path 
      d="M0.869271 3.23119C7.81367 10.3724 14.7605 17.5149 21.7411 24.6937C14.7992 31.9099 7.88255 39.0999 0.86323 46.3974C0.732728 45.8112 0.611892 45.3274 0.522474 44.8387C0.483807 44.6274 0.506766 44.4024 0.506766 44.1837C0.506766 31.2199 0.506766 18.2562 0.504349 5.29244C0.504349 4.55494 0.567183 3.83619 0.87048 3.23244L0.869271 3.23119Z" 
      fill="white"
    />
    <path 
      d="M32.951 32.2637C30.4824 29.7237 28.0536 27.2249 25.6308 24.7312C28.0874 22.2237 30.5428 19.7162 33.0356 17.1699C35.1635 18.4562 37.4364 19.7549 39.632 21.1799C42.1345 22.8037 42.1188 26.5999 39.6078 28.2187C37.4207 29.6299 35.1635 30.9249 32.9498 32.2637H32.951Z" 
      fill="white"
    />
  </svg>
);

export default GooglePlayIcon;
