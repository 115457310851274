import React, { cloneElement, useEffect, useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import {
  Modal,
  UbisoftPlusLogo,
  RewardIcon,
  DiamondIcon,
  ReplayIcon,
  GamesIcon,
  ScrollableSection,
  CheckIcon,
} from '@pdp-rework-hub/ui';
import { ModalContainer, GameMosaicContainer, LeftImage } from './style';
import { getStoreRelativeURL, sendAdobeEvent } from '@pdp-rework-hub/utils';
import { IAdobeProductImpressionEvent } from '@pdp-rework-hub/utils-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { SUBSCRIPTIONS, WEBDAV_ASSETS_URL } from '@pdp-rework-hub/utils-constants';

interface ISubscriptionModalProps extends WithTranslation {
  textColor: string;
  backgroundColor: string;
  isSubscriptionModalOpen: boolean;
  setIsSubscriptionModalOpen: (value: boolean) => void;
  isClassicsSubscription: boolean;
}

interface ISubscriptionItem {
  icon: string;
  title: string;
  description: string;
}

const SubscriptionModal = ({
  backgroundColor,
  t,
  textColor,
  isSubscriptionModalOpen,
  setIsSubscriptionModalOpen,
  isClassicsSubscription,
}: ISubscriptionModalProps) => {
  const contentSelector = useSelector((state: RootState) => ({
    subscriptions: state.products.data.subscriptions,
  }));
  const { subscriptions } = contentSelector;
  const theme = useTheme();
  const ubisoftplusRedirectionValue = getStoreRelativeURL('ubisoftplus');
  const typeSubscription = isClassicsSubscription ? SUBSCRIPTIONS.BASIC.TYPE : SUBSCRIPTIONS.PREMIUM.TYPE;

  const sendUbisoftPlusTracking = useCallback(() => {
    const trackingEvent = {
      location: 'comparison table',
      locationDetail: 'subscription detail',
      action: `learn more about ubisoft plus`,
      category: 'action',
      locationPosition: 'overlay',
    };
    sendAdobeEvent(trackingEvent);
  }, []);

  useEffect(() => {
    if (isSubscriptionModalOpen) {
      const trackingEvent: IAdobeProductImpressionEvent = {
        location: 'comparison table',
        locationDetail: 'subscription detail',
        action: 'visible',
        category: 'display',
        locationPosition: 'pop in',
        productImpression: [...subscriptions.map((subscription) => subscription.id)],
      };
      sendAdobeEvent(trackingEvent, 'impressionevent');
    }
  }, [isSubscriptionModalOpen]);

  const itemsTranslations = t(`subscriptionModal.${typeSubscription}.items`, {
    returnObjects: true,
  }) as ISubscriptionItem[];
  const itemsList = itemsTranslations.map((item, index) => {
    let icon = <CheckIcon />;
    switch (item.icon) {
      case 'games':
        icon = <GamesIcon />;
        break;
      case 'diamond':
        icon = <DiamondIcon />;
        break;
      case 'reward':
        icon = <RewardIcon />;
        break;
      case 'replay':
        icon = <ReplayIcon />;
        break;
    }
    return (
      <Container key={`ubisoft-plus-feature-${index}`} display={'flex'} marginBottom={'1.25rem'}>
        <Container marginRight={'1rem'}>{cloneElement(icon, { color: textColor })}</Container>
        <Container>
          {item.title && (
            <Text.Heading
              bold
              fontSize={'1.125rem'}
              color={theme.colors.UbisoftPlus}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          )}
          {item.description && (
            <Text.Paragraph
              fontSize="unset"
              color="unset"
              fontFamily="unset"
              textAlign="unset"
              lineHeight={'1.5rem'}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </Container>
      </Container>
    );
  });

  return isSubscriptionModalOpen ? (
    <ModalContainer textColor={textColor}>
      <Modal
        offsetY="5vh"
        animated
        handleClose={() => setIsSubscriptionModalOpen(false)}
        isOpen={isSubscriptionModalOpen}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        <Container
          width={'100%'}
          display={'flex'}
          alignItems={'stretch'}
          flexDirection={{
            default: 'column',
            tablet: 'row',
          }}
          padding={{
            default: '5.125rem 1.25rem 1.25rem',
            tabletLarge: '2.5rem 4.125rem 2.5rem 2.5rem',
          }}
          maxHeight={'90vh'}
          overflow={'hidden'}
        >
          {isClassicsSubscription ? (
            <Container
              flex={{
                default: '1',
                tablet: '0 0 40%',
              }}
              maxWidth={{
                default: '100%',
                tablet: '40%',
              }}
            >
              <LeftImage src={`${WEBDAV_ASSETS_URL}/classicsCollection.png`} loading={'lazy'} alt={''} />
            </Container>
          ) : (
            <GameMosaicContainer backgroundImage={`${WEBDAV_ASSETS_URL}/gamemosaic.jpg`}>
              <Container
                padding={{
                  default: '1rem',
                  tablet: '0',
                }}
              >
                <UbisoftPlusLogo />
                <Text.Paragraph bold color={theme.colors.White} marginBottom={'0'} textAlign={'center'}>
                  {t('global.subscription.service')}
                </Text.Paragraph>
              </Container>
            </GameMosaicContainer>
          )}
          <Container
            display={'flex'}
            flexDirection={'column'}
            overflow={'hidden'}
            maxHeight={'100%'}
            marginTop={{
              default: '1.5rem',
              tablet: '0',
            }}
            marginLeft={{
              tablet: '2.875rem',
            }}
          >
            <Text.Heading
              bold
              fontSize={'1.875rem'}
              dangerouslySetInnerHTML={{ __html: t(`subscriptionModal.${typeSubscription}.title`) }}
              marginBottom={'1.5rem'}
            />
            <ScrollableSection expand>
              <Container>{itemsList}</Container>
              <Container>
                <a href={ubisoftplusRedirectionValue} target={'_blank'} onClick={() => sendUbisoftPlusTracking()}>
                  {t('subscriptionModal.learnMoreAboutUbisoftPlus')}
                </a>
              </Container>
            </ScrollableSection>
          </Container>
        </Container>
      </Modal>
    </ModalContainer>
  ) : null;
};

export default withTranslation()(SubscriptionModal);
