export default {
  translations: {
    title: 'Compara {{gameName}} le edizioni',
    subscription: {
      title: 'Incluso in Ubisoft<span class="blue">+</span>',
      classics: { title: 'Collezione Classics', subtitle: 'Scopri i giochi Ubisoft più amati' },
      premium: { title: 'Più di 100 giochi PC', subtitle: 'Nuove uscite comprese' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          {
            description: 'Gioca a una selezione sempre più ricca di giochi indimenticabili con Ubisoft Connect',
            icon: 'check',
          },
          { description: 'Accedi alle edizioni standard', icon: 'check' },
          { description: 'Sconto del 20% su giochi e DLC', icon: 'check' },
        ],
      },
      premium: {
        title: 'Che cos\'è Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'Nuove uscite e oltre 100 giochi per PC',
            description: 'Gioca a giochi selezionati dal cloud e a un catalogo esteso di oltre 100 giochi su PC',
            icon: 'games',
          },
          {
            title: 'Edizioni Premium',
            description: "Ottieni l'accesso alle espansioni, ai pass stagionali e a ulteriori contenuti",
            icon: 'diamond',
          },
          {
            title: 'Ricompense mensili',
            description: 'Ogni mese, ricevi nuovi oggetti di personalizzazione, booster e premi di gioco.',
            icon: 'reward',
          },
          {
            title: 'Cancella e torna in qualsiasi momento',
            description: "Torna facilmente all'abbonamento quando vuoi",
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Per saperne di più su Ubisoft+',
    },
  },
};
