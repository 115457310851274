import styled from 'styled-components';
import { getBreakpointValue } from '@pdp-rework-hub/utils';

interface IModalContainer {
  textColor: string;
}

export const ModalContainer = styled.div<IModalContainer>`
  .modal-container-mate {
    max-width: 56.25rem;
  }

  a {
    color: ${({ textColor }) => textColor};
  }
`;

export const LeftImage = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 180px;

  @media screen and (min-width: ${getBreakpointValue('tablet', 'min')}) {
    max-height: inherit;
  }
`;

interface IGameMosaicContainer {
  backgroundImage: string;
}

export const GameMosaicContainer = styled.div<IGameMosaicContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 40%;
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-position: 50% 50%;
  background-size: cover;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) 100%);
  }

  * {
    position: relative;
    z-index: 1;
  }
`;
