import React, { useCallback, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { ChevronRightIcon } from '@pdp-rework-hub/ui';
import {
  ChevronContainer,
  OfferItemContainer,
  OfferThumbnail,
  OfferThumbnailImage,
  OfferTitleContainer,
  OfferTitle,
  OfferSubtitle,
  OfferButton,
} from '../Offers/OfferItem/style';
import { TitleContainer, ListContainer } from './style';

import SubscriptionModal from '../SubscriptionModal';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import { IAdobeProductClickEvent, IMaster, ISubscription } from '@pdp-rework-hub/utils-types';
import { SUBSCRIPTION_IDS, SUBSCRIPTIONS, WEBDAV_ASSETS_URL } from '@pdp-rework-hub/utils-constants';

interface ISubscriptionFeaturesProps extends WithTranslation {
  product: IMaster;
  backgroundColor: string;
  textColor: string;
  slotNumber: number;
  locationPosition: string | null;
  currentSubscription: ISubscription;
}

const SubscriptionFeatures = ({
  backgroundColor,
  t,
  textColor,
  product,
  slotNumber,
  locationPosition,
  currentSubscription,
}: ISubscriptionFeaturesProps) => {
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const isClassicsSubscription = currentSubscription.id === SUBSCRIPTION_IDS.BASIC;
  const translationKeyCategory = isClassicsSubscription ? SUBSCRIPTIONS.BASIC.TYPE : SUBSCRIPTIONS.PREMIUM.TYPE;

  const handleClick = useCallback(() => {
    const { id, name, edition } = product;
    const trackingEvent: IAdobeProductClickEvent = {
      location: 'comparison table',
      locationDetail: 'subscription detail',
      category: 'interaction',
      action: 'ubisoft plus : display subscription details',
      productId: id,
      productName: name,
      productEdition: edition,
      slotNumber,
    };
    if (locationPosition) {
      trackingEvent.locationPosition = locationPosition;
    }
    sendAdobeEvent(trackingEvent);
    setIsSubscriptionModalOpen(true);
  }, [setIsSubscriptionModalOpen, product, sendAdobeEvent, locationPosition]);

  return (
    <Container width={'100%'} marginBottom={'1.5rem'}>
      <TitleContainer backgroundColor={backgroundColor} textColor={textColor}>
        <Text.Heading bold dangerouslySetInnerHTML={{ __html: t('subscription.title') }} />
      </TitleContainer>
      <ListContainer>
        <OfferItemContainer
          initial={{
            opacity: 0,
            y: 3,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            type: 'spring',
            delay: 0.2,
          }}
          viewport={{
            once: true,
          }}
          onClick={() => handleClick()}
        >
          <OfferButton textColor={textColor}>
            <OfferThumbnail>
              <OfferThumbnailImage src={`${WEBDAV_ASSETS_URL}/icon-subscription-feature.png`} alt={''} />
            </OfferThumbnail>
            <OfferTitleContainer>
              <OfferTitle>{t(`subscription.${translationKeyCategory}.title`)}</OfferTitle>
              <OfferSubtitle>{t(`subscription.${translationKeyCategory}.subtitle`)}</OfferSubtitle>
            </OfferTitleContainer>
            <ChevronContainer>
              <ChevronRightIcon color={textColor} />
            </ChevronContainer>
          </OfferButton>
        </OfferItemContainer>
      </ListContainer>
      <SubscriptionModal
        textColor={textColor}
        backgroundColor={backgroundColor}
        isSubscriptionModalOpen={isSubscriptionModalOpen}
        setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
        isClassicsSubscription={isClassicsSubscription}
      />
    </Container>
  );
};

export default withTranslation()(SubscriptionFeatures);
