import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IMaster, hasPackshot } from '@pdp-rework-hub/utils-types';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { EditionHeaderWrapper, EditionHeaderImage, HeaderBackgroundImage } from './style';
import { useTheme } from 'styled-components';

import { SHOP_URL, WEBDAV_ASSETS_URL } from '@pdp-rework-hub/utils-constants';

interface IEditionDetails extends WithTranslation {
  product: IMaster;
  isPreOrder?: boolean;
  isSubscription: boolean;
}

const EditionDetails = ({ t, product, isPreOrder = false, isSubscription }: IEditionDetails) => {
  const theme = useTheme();
  const { media } = product;

  const productHasPackshot = hasPackshot(media);
  const horizontalImageURL = media?.horizontal?.length ? media.horizontal[0].url : null;

  const getHorizontalImage = (horizontalImageURL: string | null, productHasPackshot: boolean) => {
    if (horizontalImageURL) {
      return `${SHOP_URL + horizontalImageURL}`;
    }

    if (productHasPackshot && media?.media_slider_PD?.length) {
      return `${SHOP_URL + media.media_slider_PD[0].url}`;
    }

    return `${WEBDAV_ASSETS_URL}/fallback-horizontal-asset.jpg`;
  };

  const backgroundImage = getHorizontalImage(horizontalImageURL, productHasPackshot);

  let packshotImage;
  if (productHasPackshot && !horizontalImageURL) {
    packshotImage = SHOP_URL + media.edition_packshot[0].url;
  }

  return (
    <Container
      position={'relative'}
      backgroundImage={`url(${backgroundImage})`}
      backgroundSize={'cover'}
      backgroundPosition={'50% 50%'}
      padding={'0 0 53% 0'}
      borderRadius={'8px 8px 0 0'}
      overflow={'hidden'}
    >
      <EditionHeaderWrapper isHorizontal={!productHasPackshot || !!horizontalImageURL}>
        {isSubscription && (
          <>
            <Container
              position={'relative'}
              zIndex={'2'}
              width={'100%'}
              backgroundColor={theme.colors.UbisoftPlus}
              color={theme.colors.White}
              textAlign={'center'}
              padding={'0.5rem'}
              marginBottom={'auto'}
            >
              <Text.Span fontSize={'1.125rem'} bold>
                {isPreOrder ? t('global.subscription.dayOne') : t('global.subscription.includedWithUbisoftPlus')}
              </Text.Span>
            </Container>
            <HeaderBackgroundImage isHorizontal={!productHasPackshot || !!horizontalImageURL}>
              <img src={backgroundImage} alt={''} loading={'lazy'} />
            </HeaderBackgroundImage>
          </>
        )}
        {packshotImage ? (
          <EditionHeaderImage isSubscription={isSubscription}>
            <img src={packshotImage} alt={product.edition || ''} loading={'lazy'} />
          </EditionHeaderImage>
        ) : null}
      </EditionHeaderWrapper>
    </Container>
  );
};

export default withTranslation()(EditionDetails);
