import React from 'react';
import { useTheme } from 'styled-components';

const RenewIcon = () => {
  const theme = useTheme();
  return (
    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.34021 14.0199C5.5802 7.35119 10.9582 1 19.5254 1C28.0927 1 33.7901 7.98631 34.7201 14.0199"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1.27212"
        y1="9.67788"
        x2="3.27927"
        y2="15.4755"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="9.55003"
        y1="13.4318"
        x2="3.75244"
        y2="15.4389"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7188 18.9803C33.4788 25.6491 28.1008 32.0002 19.5335 32.0002C10.9663 32.0002 5.26889 25.0139 4.33889 18.9803"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="37.5251"
        y1="23.0895"
        x2="35.518"
        y2="17.2919"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="29.7565"
        y1="19.1297"
        x2="35.5541"
        y2="17.1226"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26.4112 16.4065L16.1401 22.2757L16.1401 10.5374L26.4112 16.4065Z" fill={theme.colors.DodgerBlue} />
    </svg>
  );
};

export default RenewIcon;
