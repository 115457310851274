import React from 'react';
import { useTheme } from 'styled-components';

interface IGamesIcon {
  color?: string;
}

const GamesIcon = ({ color = 'black' }: IGamesIcon) => {
  const theme = useTheme();
  return (
    <svg width="40" height="45" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6367 19.0449C13.6367 16.0583 16.0578 13.6372 19.0444 13.6372H34.5923C37.5788 13.6372 39.9999 16.0583 39.9999 19.0449V39.4217C39.9999 42.4083 37.5788 44.8294 34.5923 44.8294H19.0444C16.0578 44.8294 13.6367 42.4083 13.6367 39.4217V19.0449ZM19.0444 16.9288C17.8757 16.9288 16.9284 17.8762 16.9284 19.0449V39.4217C16.9284 40.5903 17.8757 41.5377 19.0444 41.5377H34.5923C35.7609 41.5377 36.7083 40.5903 36.7083 39.4217V19.0449C36.7083 17.8762 35.7609 16.9288 34.5923 16.9288H19.0444Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74219 12.1479C6.74219 9.16133 9.16329 6.74023 12.1499 6.74023H28.4814C31.0352 6.74023 33.1054 8.81045 33.1054 11.3642H29.8138C29.8138 10.6284 29.2173 10.0319 28.4814 10.0319H12.1499C10.9812 10.0319 10.0338 10.9793 10.0338 12.1479V33.3084C10.0338 34.0443 10.6303 34.6408 11.3662 34.6408V37.9324C8.81241 37.9324 6.74219 35.8622 6.74219 33.3084V12.1479Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.40768C0 2.4211 2.42111 0 5.40769 0H21.7393C24.293 0 26.3632 2.07022 26.3632 4.62396H23.0716C23.0716 3.88814 22.4751 3.29164 21.7393 3.29164H5.40769C4.23902 3.29164 3.29164 4.23902 3.29164 5.40768V26.5682C3.29164 27.304 3.88814 27.9005 4.62396 27.9005V31.1922C2.07022 31.1922 0 29.1219 0 26.5682V5.40768Z"
        fill={color}
      />
      <path
        d="M28.5842 27.6603V22.8066H25.3119V27.6603H24.0692H20.457V30.9313H25.3115V35.7838H28.5838V30.9313H32.6237L33.9984 27.6603H28.5842Z"
        fill={theme.colors.UbisoftPlus}
      />
    </svg>
  );
};

export default GamesIcon;
