import React from 'react';
import { useTheme } from 'styled-components';

interface IDiamondIcon {
  color?: string;
}

const DiamondIcon = ({ color = 'black' }: IDiamondIcon) => {
  const theme = useTheme();
  return (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.43262 11.5883L1.8105 9.09424L13.6085 10.8818H26.3914L38.1894 9.09424L38.5673 11.5883L26.5814 13.4043H13.4184L1.43262 11.5883Z"
        fill={theme.colors.UbisoftPlus}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.398 2.05356L14.2358 11.7984L12.125 11.3301L14.2872 1.58522L16.398 2.05356Z"
        fill={theme.colors.UbisoftPlus}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6059 1.69223L25.7681 11.4371L27.8789 10.9688L25.7167 1.22389L23.6059 1.69223Z"
        fill={theme.colors.UbisoftPlus}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9398 33.7796L11.5772 11.8127L13.5801 10.9964L21.9427 32.9632L19.9398 33.7796Z"
        fill={theme.colors.UbisoftPlus}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0611 33.7796L28.4238 11.8127L26.4209 10.9964L18.0582 32.9632L20.0611 33.7796Z"
        fill={theme.colors.UbisoftPlus}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84824 0.444998C9.08788 0.162716 9.43946 0 9.80975 0H30.1903C30.5605 0 30.9121 0.162716 31.1518 0.444998L39.7002 10.5146C40.0999 10.9854 40.0999 11.6763 39.7002 12.1471L20.9615 34.2201C20.7219 34.5024 20.3703 34.6651 20 34.6651C19.6297 34.6651 19.2781 34.5024 19.0385 34.2201L0.299755 12.1471C-0.0999183 11.6763 -0.0999184 10.9854 0.299755 10.5146L8.84824 0.444998ZM10.3935 2.52252L2.91573 11.3308L20 31.455L37.0843 11.3308L29.6065 2.52252H10.3935Z"
        fill={color}
      />
    </svg>
  );
};

export default DiamondIcon;
