import React from 'react';
import { useTheme } from 'styled-components';

interface IReplayIcon {
  color?: string;
}

const ReplayIcon = ({ color = 'black' }: IReplayIcon) => {
  const theme = useTheme();
  return (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.994 2.51369C11.9934 2.51369 6.92848 8.39939 5.7504 14.7132C5.62308 15.3955 4.96671 15.8455 4.28435 15.7182C3.60199 15.5908 3.15204 14.9345 3.27936 14.2521C4.62926 7.01752 10.5285 0 19.994 0C29.4609 0 35.7005 7.66874 36.7247 14.2905C36.8308 14.9765 36.3607 15.6186 35.6747 15.7247C34.9888 15.8308 34.3466 15.3607 34.2405 14.6748C33.3688 9.0385 27.993 2.51369 19.994 2.51369Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.1883 9.39751C1.84423 9.17042 2.56007 9.51807 2.78716 10.174L4.70866 15.7242C4.93575 16.3802 4.5881 17.096 3.93216 17.3231C3.27622 17.5502 2.56039 17.2025 2.3333 16.5466L0.411794 10.9964C0.184706 10.3404 0.532357 9.6246 1.1883 9.39751Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3417 14.0443C10.5688 14.7002 10.2212 15.416 9.56525 15.6431L4.01505 17.5646C3.35911 17.7917 2.64327 17.4441 2.41618 16.7881C2.1891 16.1322 2.53675 15.4163 3.19268 15.1893L8.74289 13.2678C9.39883 13.0407 10.1147 13.3883 10.3417 14.0443Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.007 31.9595C28.0076 31.9595 33.0725 26.0738 34.2506 19.76C34.3779 19.0776 35.0343 18.6277 35.7166 18.755C36.399 18.8823 36.8489 19.5387 36.7216 20.221C35.3717 27.4556 29.4725 34.4731 20.007 34.4731C10.54 34.4731 4.3005 26.8044 3.27628 20.1826C3.17018 19.4966 3.64027 18.8545 4.32624 18.7484C5.01222 18.6423 5.65433 19.1124 5.76043 19.7984C6.63221 25.4346 12.008 31.9595 20.007 31.9595Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8127 25.0771C38.1567 25.3042 37.4409 24.9565 37.2138 24.3006L35.2923 18.7504C35.0652 18.0945 35.4129 17.3786 36.0688 17.1515C36.7248 16.9244 37.4406 17.2721 37.6677 17.928L39.5892 23.4782C39.8163 24.1342 39.4686 24.85 38.8127 25.0771Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6592 20.4308C29.4321 19.7749 29.7798 19.0591 30.4357 18.832L35.9859 16.9105C36.6419 16.6834 37.3577 17.031 37.5848 17.687C37.8119 18.3429 37.4642 19.0588 36.8083 19.2858L31.2581 21.2073C30.6022 21.4344 29.8863 21.0868 29.6592 20.4308Z"
        fill={color}
      />
      <path d="M26.9325 16.8777L15.4414 23.521L15.4414 10.2344L26.9325 16.8777Z" fill={theme.colors.UbisoftPlus} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1728 9.76789C15.3395 9.67169 15.5449 9.67178 15.7116 9.76812L27.2027 16.4114C27.3692 16.5077 27.4718 16.6854 27.4718 16.8778C27.4718 17.0701 27.3692 17.2478 27.2027 17.3441L15.7116 23.9874C15.5449 24.0838 15.3395 24.0838 15.1728 23.9876C15.006 23.8914 14.9033 23.7136 14.9033 23.5211L14.9033 10.2344C14.9033 10.042 15.006 9.86409 15.1728 9.76789ZM15.9806 11.168L15.9806 22.5875L25.8569 16.8778L15.9806 11.168Z"
        fill={theme.colors.UbisoftPlus}
      />
    </svg>
  );
};

export default ReplayIcon;
