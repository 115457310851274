export const LANG_PARAM_KEY = 'lang';

export const getStoreRelativeURL = (redirectionValue: string) => {
  const params = new URLSearchParams(window.location.search);
  const lang = params.get(LANG_PARAM_KEY);
  const upcPrefix = window.location.pathname.indexOf('/upc/') > -1 ? '/upc' : '';

  if (!lang) {
    return `${upcPrefix}/${redirectionValue}`;
  }

  return `${upcPrefix}/${redirectionValue}?${LANG_PARAM_KEY}=${lang}`;
};
